// clientName MUST be specified, as this is used to build the API URL
// Regex for one lower, one upper, one special, one number, at least 8 characters =
//     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";

const ClientConfig = {
  clientName: 'unc-health',
  environment: {
    development: {
      login: 'login',
      ssoLogin: 'http://api.beamapi.localdev/v1/authentication/sso?sp=default-sp&redirect_url=http://bain.localdev:8080',
      logout: 'login'
    },
    production: {
      login: 'login',
      ssoLogin: ['https://authenticator.monigle.net/v1/authentication/sso?sp=prod-sp-unc-health&redirect_url=https://brand.unchealth.org'],
      logout: 'login'
    },
    qa: {
      login: 'login',
      ssoLogin: ['https://authenticator.monigle3.net/v1/authentication/sso?sp=qa-sp-unc&redirect_url=https://unc-health.beam3.monigle3.net'],
      logout: 'login'
    },
    staging: {
      login: 'login',
      ssoLogin: ['https://authenticator.monigle2.net/v1/authentication/sso?sp=staging-sp-onelogin&redirect_url=https://unc-health.beam3.monigle2.net'],
      logout: 'login'
    },
    demo: {
      login: 'login',
      ssoLogin: 'https://beam3api-demo.monigle3.net/v1/authentication/sso?sp=demo-sp&redirect_url=https://unc-health-demo.beam3.monigle3.net',
      logout: 'login'
    }
  },
  settings: {
    // passwordRegex: '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})',
    passwordRequirements: {
      length: 8,
      lowercase: 1,
      number: 1,
      symbol: 1,
      uppercase: 1
    }
  },
  theme: {
    themeFonts: {
      font_5: {
        name: 'MullerHairline',
        value: 'MullerHairline, arial, sans-serif'
      },
      font_6: {
        name: 'MullerHairlineItalic',
        value: 'MullerHairlineItalic, arial, sans-serif'
      },
      font_7: {
        name: 'MullerNarrowHairline',
        value: 'MullerNarrowHairline, arial, sans-serif'
      },
      font_8: {
        name: 'MullerNarrowHairlineItalic',
        value: 'MullerNarrowHairlineItalic, arial, sans-serif'
      },
      font_9: {
        name: 'MullerThin',
        value: 'MullerThin, arial, sans-serif'
      },
      font_10: {
        name: 'MullerThinItalic',
        value: 'MullerThinItalic, arial, sans-serif'
      },
      font_11: {
        name: 'MullerNarrowThin',
        value: 'MullerNarrowThin, arial, sans-serif'
      },
      font_12: {
        name: 'MullerNarrowThinItalic',
        value: 'MullerNarrowThinItalic, arial, sans-serif'
      },
      font_13: {
        name: 'MullerUltraLight',
        value: 'MullerUltraLight, arial, sans-serif'
      },
      font_14: {
        name: 'MullerUltraLightItalic',
        value: 'MullerUltraLightItalic, arial, sans-serif'
      },
      font_15: {
        name: 'MullerNarrowUltraLight',
        value: 'MullerNarrowUltraLight, arial, sans-serif'
      },
      font_16: {
        name: 'MullerNarrowUltraLightItalic',
        value: 'MullerNarrowUltraLightItalic, arial, sans-serif'
      },
      font_17: {
        name: 'MullerLight',
        value: 'MullerLight, arial, sans-serif'
      },
      font_18: {
        name: 'MullerLightItalic',
        value: 'MullerLightItalic, arial, sans-serif'
      },
      font_19: {
        name: 'MullerNarrowLight',
        value: 'MullerNarrowLight, arial, sans-serif'
      },
      font_20: {
        name: 'MullerNarrowLightItalic',
        value: 'MullerNarrowLightItalic, arial, sans-serif'
      },
      font_21: {
        name: 'MullerRegular',
        value: 'MullerRegular, arial, sans-serif'
      },
      font_22: {
        name: 'MullerRegularItalic',
        value: 'MullerRegularItalic, arial, sans-serif'
      },
      font_23: {
        name: 'MullerNarrowRegular',
        value: 'MullerNarrowRegular, arial, sans-serif'
      },
      font_24: {
        name: 'MullerNarrowRegularItalic',
        value: 'MullerNarrowRegularItalic, arial, sans-serif'
      },
      font_25: {
        name: 'MullerMedium',
        value: 'MullerMedium, arial, sans-serif'
      },
      font_26: {
        name: 'MullerMediumItalic',
        value: 'MullerMediumItalic, arial, sans-serif'
      },
      font_27: {
        name: 'MullerNarrowMedium',
        value: 'MullerNarrowMedium, arial, sans-serif'
      },
      font_28: {
        name: 'MullerNarrowMediumItalic',
        value: 'MullerNarrowMediumItalic, arial, sans-serif'
      },
      font_29: {
        name: 'MullerHeavy',
        value: 'MullerHeavy, arial, sans-serif'
      },
      font_30: {
        name: 'MullerHeavyItalic',
        value: 'MullerHeavyItalic, arial, sans-serif'
      },
      font_31: {
        name: 'MullerNarrowHeavy',
        value: 'MullerNarrowHeavy, arial, sans-serif'
      },
      font_32: {
        name: 'MullerNarrowHeavyItalic',
        value: 'MullerNarrowHeavyItalic, arial, sans-serif'
      },
      font_33: {
        name: 'MullerFat',
        value: 'MullerFat, arial, sans-serif'
      },
      font_34: {
        name: 'MullerFatItalic',
        value: 'MullerFatItalic, arial, sans-serif'
      },
      font_35: {
        name: 'MullerBold',
        value: 'MullerBold, arial, sans-serif'
      },
      font_36: {
        name: 'MullerBoldItalic',
        value: 'MullerBoldItalic, arial, sans-serif'
      },
      font_37: {
        name: 'MullerExtraBold',
        value: 'MullerExtraBold, arial, sans-serif'
      },
      font_38: {
        name: 'MullerExtraBoldItalic',
        value: 'MullerExtraBoldItalic, arial, sans-serif'
      },
      font_39: {
        name: 'MullerNarrowBold',
        value: 'MullerNarrowBold, arial, sans-serif'
      },
      font_40: {
        name: 'MullerNarrowBoldItalic',
        value: 'MullerNarrowBoldItalic, arial, sans-serif'
      },
      font_41: {
        name: 'MullerNarrowExtraBold',
        value: 'MullerNarrowExtraBold, arial, sans-serif'
      },
      font_42: {
        name: 'MullerNarrowExtraBoldItalic',
        value: 'MullerNarrowExtraBoldItalic, arial, sans-serif'
      },
      font_43: {
        name: 'MullerBlack',
        value: 'MullerBlack, arial, sans-serif'
      },
      font_44: {
        name: 'MullerBlackItalic',
        value: 'MullerBlackItalic, arial, sans-serif'
      },
      font_45: {
        name: 'MullerNarrowBlack',
        value: 'MullerNarrowBlack, arial, sans-serif'
      },
      font_46: {
        name: 'MullerNarrowBlackItalic',
        value: 'MullerNarrowBlackItalic, arial, sans-serif'
      },
      font_47: {
        name: 'MullerUltraBlack',
        value: 'MullerUltraBlack, arial, sans-serif'
      },
      font_48: {
        name: 'MullerUltraBlackItalic',
        value: 'MullerUltraBlackItalic, arial, sans-serif'
      }
    },
    themeStyles: {
      hasAltHomepageDesktopLogo: false
    }
  },
  include_modules: [],
  exclude_modules: [],
  request_headers: require('@core/js/config/request-headers')
}
export default ClientConfig
